































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Loader from "@/helpers/loader";
import Notifier from "@/helpers/notifier";
import agent from "@/helpers/agent";
import {
  ApplicationStatus,
  BusinessProfileVM,
} from "@/models/businessApplication";
import uid from 'quasar/src/utils/uid.js';;

@Component
export default class Profile extends Vue {
  shape = "";
  businessProfile: BusinessProfileVM = {
    id: "",
    businessName: "",
    type: 0,
    reference: "",
    status: ApplicationStatus.Pending,
    legalProfileStatus: "",
    businessProfileStatus: "",
    formRStatus: "",
    formJStatus: "",
    bankingProfileStatus: "",
    additionalProfileStatus: "",
    isIndividual: true,
  };
  entityTypes = [
    {
      label: "Sole Trader",
      value: 0,
    },
    {
      label: "Company",
      value: 1,
    },
  ];
  onSubmit() {
    Loader.showLoader();
    if (this.businessProfile.id.length === 0) {
      this.businessProfile.id = uid();
      agent.Applications.createProfile(this.businessProfile)
        .then(() => {
          Loader.hideLoader();
          Notifier.showPositive(
            `You have succesfully created a business profile`
          );
          this.$router.push({
            name: "ProfilesApplication",
            query: { id: this.businessProfile.id },
          });
        })
        .catch(() => [
          Loader.hideLoader(),
          Notifier.showError(
            "There was an error creating the business profile"
          ),
        ]);
      return;
    }
    agent.Applications.update(this.businessProfile)
      .then(() => {
        Loader.hideLoader();
        Notifier.showPositive(
          `You have succesfully updated the business profile`
        );
        this.$router.push("/list-applications");
      })
      .catch(() => [
        Loader.hideLoader(),
        Notifier.showError("There was an error updating the business profile"),
      ]);
  }
  async mounted() {
    if (this.$route.query.id) {
      this.businessProfile = await agent.Applications.getProfile(
        this.$route.query.id as string
      );
    }
  }
}
